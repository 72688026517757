import { React, useRef, useState, useLayoutEffect, useEffect } from "react";

import logo from "../Images/logo.webp";
import onBack from "../Images/onBack.png";
import onSide from "../Images/onSide.png";
import onStomich from "../Images/onStomich.png";
import twin from "../Images/twin.jpg";
import twinXl from "../Images/twin_xl.jpg";
import full from "../Images/full.jpg";
import queen from "../Images/queen.jpg";
import king from "../Images/king.jpg";
import calKing from "../Images/cal_king.jpg";

import "./new-register.css";
import "bootstrap/dist/css/bootstrap.min.css";
import secureLocalStorage from "react-secure-storage";

import RegisterService from "../services/register.service";

import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

// import ReCAPTCHA from "react-google-recaptcha";

import $ from "jquery";

import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";

function RegisterForm(props) {
  const TITLE = process.env.REACT_APP_API_SITE_TITLE + " | Registration Form";
  const [fields, setFields] = useState({
    name: "",
    lname: "",
    phone: "",
    emailid: "",
    store_location: "",
    sales_name_f: "",
    sales_name_l: "",
    question: "",
    question5: "",
    comments: "",
    captcha: "",
  });
  const recaptchaRef = useRef(null);

  const [captchaError, setCaptchaError] = useState("Loading");
  const [question2, setquestion2] = useState("");
  const [question3, setquestion3] = useState("");
  const [question4, setquestion4] = useState("");
  const reloadCaptcha = async () => {
    if (recaptchaRef.current) {
      setCaptchaError("Loading");
      await recaptchaRef.current.reset();
    }
  };

  useEffect(() => {
    const interval = setInterval(reloadCaptcha, 3600000);

    return () => clearInterval(interval);
  }, []);

  const [loading, setLoading] = useState(false);
  const [storeLoading, setStoreLoading] = useState(false);

  const [errors, setErrors] = useState({});
  // const [captcha, setCaptcha] = useState(false);

  const [locations] = useState([
    {
      value: "1",
      label: "Brandon",
    },
    {
      value: "2",
      label: "Casselberry",
    },

    {
      value: "3",
      label: "Clearwater",
    },
    {
      value: "16",
      label: "Dale Mabry",
    },
    {
      value: "4",
      label: "Fort Myers",
    },

    {
      value: "5",
      label: "Lakeland",
    },
    {
      value: "6",
      label: "Melbourne",
    },

    {
      value: "7",
      label: "Naples",
    },
    {
      value: "8",
      label: "New Port Richey",
    },
    {
      value: "9",
      label: "Ocala",
    },
    {
      value: "10",
      label: "Ocoee",
    },
    {
      value: "11",
      label: "Orlando East Colonial",
    },
    {
      value: "12",
      label: "Orlando OBT",
    },
    {
      value: "13",
      label: "Port Charlotte",
    },
    {
      value: "14",
      label: "St. Petersburg",
    },
    {
      value: "15",
      label: "Sarasota",
    },
  ]);

  const [question] = useState([
    {
      value: "Beautyrest Black",
      label: "Beautyrest Black",
    },
    {
      value: "Serta iComfort",
      label: "Serta iComfort",
    },
    {
      value: "Tommy Bahama",
      label: "Tommy Bahama",
    },
  ]);

  const [question5] = useState([
    {
      value: "Yes",
      label: "Yes",
    },
    {
      value: "No",
      label: "No",
    },
  ]);
  const [signoutTime, setSignoutTime] = useState(900000);
  const [value_userform, setValue_userform] = useState([]);

  const navigate = useNavigate();

  secureLocalStorage.removeItem("registerform");

  timeout();
  function timeout() {
    setTimeout(function () {
      logout();
    }, signoutTime);
  }

  const logout = () => {
    destroy();
  };

  const destroy = () => {
    localStorage.setItem("returnpage", true);
    localStorage.removeItem("return_page");
    localStorage.removeItem("registerForm");
    localStorage.removeItem("isRegister");
  };

  const ref = useRef();

  const pasted = (e) => {
    e.preventDefault();
    return false;
  };

  const onlyNumbers = (evt) => {
    const { name, value } = evt.target;
    evt.target.value = evt.target.value.replace(/[^0-9]+/g, "");

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: evt.target.value,
      };
    });
  };

  const handleChange = (e) => {
    let { value, name } = e.target;
    if (name === "state" && value === "true") {
      value = "";
    }
    if (name === "store_location" && value === "true") {
      value = "";
    }

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };
  const saveErrors = (err) => {
    setErrors(err);
  };

  const handleQuestion2Change = (e) => {
    setquestion2(e.target.value);
  };

  const handleQuestion3Change = (e) => {
    setquestion3(e.target.value);
  };
  const handleQuestion4Change = (e) => {
    setquestion4(e.target.value);
  };

  const submituserRegistrationForm = (e) => {
    e.preventDefault();
    let data = {
      fname: fields?.name,
      lname: fields?.lname,
      phone: fields?.phone,
      email: fields?.emailid,
      store_location: fields?.store_location,
      sales_name_f: fields?.sales_name_f,
      sales_name_l: fields?.sales_name_l,
      question: fields?.question,
      question5: fields?.question5,

      comments: fields?.comments,
      question2: question2,
      question3: question3,
      question4: question4,
      // captcha: captcha,
    };

    try {
      setLoading(true);
      const responseuserform = RegisterService.regData(data);
      responseuserform
        .then((response) => {
          var valueUserform = response?.data?.status;
          setValue_userform(valueUserform);
          setLoading(false);

          secureLocalStorage.setItem(
            "registerform",
            JSON.stringify({
              name: fields.name,
              lname: fields?.lname,
              phone: fields?.phone,
              email: fields?.emailid,
              store_location: fields?.store_location,
              sales_name_f: fields?.sales_name_f,
              sales_name_l: fields?.sales_name_l,
              question: fields?.question,
              question5: fields?.question5,

              comments: fields?.comments,
              question2: question2,
              question3: question3,
              question4: question4,
              // captcha: captcha,
            })
          );
          localStorage.removeItem("return_page");
          localStorage.setItem("isRegister", true);

          navigate("/register/registration-thankyou");

          if (value_userform?.includes("redirect")) {
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          if (
            err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
          ) {
            toast.error(err?.response?.data?.message, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }

          let errorData = {};
          errorData["name"] =
            err?.response?.data?.message &&
            err?.response?.data?.message?.fname &&
            err?.response?.data?.message?.fname[0];
          errorData["lname"] =
            err?.response?.data?.message &&
            err?.response?.data?.message?.lname &&
            err?.response?.data?.message?.lname[0];
          errorData["phone"] =
            err?.response?.data?.message &&
            err?.response?.data?.message?.phone &&
            err?.response?.data?.message?.phone[0];
          errorData["emailid"] =
            err?.response?.data?.message &&
            err?.response?.data?.message?.email &&
            err?.response?.data?.message?.email[0];
          errorData["store_location"] =
            err?.response?.data?.message &&
            err?.response?.data?.message?.store_location &&
            err?.response?.data?.message?.store_location[0];
          errorData["sales_name_f"] =
            err?.response?.data?.message &&
            err?.response?.data?.message?.sales_name_f &&
            err?.response?.data?.message?.sales_name_f[0];
          errorData["sales_name_l"] =
            err?.response?.data?.message &&
            err?.response?.data?.message?.sales_name_l &&
            err?.response?.data?.message?.sales_name_l[0];
          errorData["question"] =
            err?.response?.data?.message &&
            err?.response?.data?.message?.question &&
            err?.response?.data?.message?.question[0];

          errorData["question2"] =
            err?.response?.data?.message &&
            err?.response?.data?.message?.question2 &&
            err?.response?.data?.message?.question2[0];
          errorData["question3"] =
            err?.response?.data?.message &&
            err?.response?.data?.message?.question3 &&
            err?.response?.data?.message?.question3[0];
          errorData["question4"] =
            err?.response?.data?.message &&
            err?.response?.data?.message?.question4 &&
            err?.response?.data?.message?.question4[0];
          errorData["question5"] =
            err?.response?.data?.message &&
            err?.response?.data?.message?.question5 &&
            err?.response?.data?.message?.question5[0];

          errorData["checkederrorcaptcha"] =
            err?.response?.data?.message &&
            err?.response?.data?.message?.captcha &&
            err?.response?.data?.message?.captcha[0];
          errorData["checkederrortwo"] =
            err?.response?.data?.message &&
            err?.response?.data?.message?.size &&
            err?.response?.data?.message?.size[0];
          saveErrors(errorData);
          setTimeout(() => {
            if (
              err?.response?.data?.message?.store_location &&
              !err?.response?.data?.message?.fname &&
              !err?.response?.data?.message?.lname &&
              !err?.response?.data?.message?.email &&
              !err?.response?.data?.message?.phone &&
              !err?.response?.data?.message?.sales_name_f &&
              !err?.response?.data?.message?.sales_name_l
            ) {
              $("#store_location").focus().select();
            } else if (
              err?.response?.data?.message?.question &&
              !err?.response?.data?.message?.question2 &&
              !err?.response?.data?.message?.store_location &&
              !err?.response?.data?.message?.fname &&
              !err?.response?.data?.message?.lname &&
              !err?.response?.data?.message?.email &&
              !err?.response?.data?.message?.phone &&
              !err?.response?.data?.message?.sales_name_f &&
              !err?.response?.data?.message?.sales_name_l
            ) {
              $("#question").focus().select();
            } else {
              ref?.current?.focus();
            }
          }, 200);
        });
    } catch (err) {
      alert(err);
      if (err.response.status === 429 || err.response.status === "429") {
        alert("Max Limit Reached. Please wait.");
      }
      let errorData = {};
      errorData["name"] = err?.response?.data?.message?.fname[0];
      errorData["lname"] = err?.response?.data?.message?.lname[0];
      errorData["phone"] = err?.response?.data?.message?.phone[0];
      errorData["emailid"] = err?.response?.data?.message?.emailid[0];
      errorData["store_location"] =
        err?.response?.data?.message?.store_location[0];
      errorData["sales_name_f"] = err?.response?.data?.message?.sales_name_f[0];
      errorData["sales_name_l"] = err?.response?.data?.message?.sales_name_l[0];
      errorData["question"] = err?.response?.data?.message?.question[0];
      errorData["question2"] = err?.response?.data?.message?.question2[0];
      errorData["question3"] = err?.response?.data?.message?.question3[0];
      errorData["question4"] = err?.response?.data?.message?.question4[0];
      errorData["question5"] = err?.response?.data?.message?.question5[0];

      errorData["checkederrorcaptcha"] =
        err?.response?.data?.message?.captcha[0];
      saveErrors(errorData);
    }
  };

  // function onCaptchaChange(value) {
  //   if (value === null) {
  //     setCaptcha(false);
  //   } else {
  //     setCaptcha(true);
  //   }
  // }
  // function NetworkError() {
  //   setCaptchaError(
  //     "Captcha failed to load due to a network issue. Please refresh the page."
  //   );
  // }

  const Select2Field = ({ name, label, options, value }) => {
    const selectRef = useRef();

    useLayoutEffect(() => {
      const $select = $(selectRef.current);

      $select.select2();

      $select.on("change", (event) => {
        const selectedValue = $(event.target).val();
        setFields((prevValue) => {
          return {
            ...prevValue,
            [name]: selectedValue,
          };
        });
      });

      return () => {
        $select.select2("destroy");
        $select.off("change");
      };
    }, []);

    return (
      <>
        <select
          ref={selectRef}
          name={name}
          className="form-select register-select2"
          value={value || ""}
          id={name}
        >
          <option value="" disabled={true} selected={true}>
            Select {label}
          </option>
          {options.map((opt, index) => (
            <option key={index} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </select>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <section className="form-body-center form-section-bg new-register">
        <div className="container">
          <div className="row gy-4 align-items-center">
            <div className="col-12 col-md-12 col-xl-12">
              <div className="register-logo">
                <img
                  src={logo}
                  alt="city-logo.png"
                  className="img-fluid"
                  width="360"
                  height="118"
                />
              </div>
            </div>
            <div className="col-12 col-md-12 col-xl-12">
              <div className="d-flex justify-content-center text-bg-primary top-bg">
                <div className="col-12 col-xl-12 text-center">
                  <h2 className="form-heading">
                    Register your customer for their $25 Mastercard Reward Card!
                  </h2>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-12 col-xl-12">
              <div className="card border-0 rounded-4 p-0">
                <div className="card-body p-3 p-md-4 p-xl-5">
                  <form
                    onSubmit={submituserRegistrationForm}
                    name="userRegistrationForm"
                  >
                    <div className="row gy-3 overflow-hidden">
                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-account-o icon-position"></i>

                          <input
                            type="text"
                            className="form-control"
                            style={
                              errors.name ? { border: "1px solid red" } : null
                            }
                            name="name"
                            placeholder="Customer First Name"
                            ref={errors.name ? ref : null}
                            value={fields.name || ""}
                            autoFocus=""
                            onChange={handleChange}
                          />

                          <label className="form-label">
                            Customer First Name
                            <span className="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg">{errors.name}</div>
                      </div>

                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="form-floating">
                          <i className="zmdi zmdi-account-o icon-position"></i>

                          <input
                            type="text"
                            className="form-control"
                            name="lname"
                            style={
                              errors.lname && !errors.name
                                ? { border: "1px solid red" }
                                : null
                            }
                            placeholder="Customer Last Name"
                            value={fields.lname || ""}
                            ref={errors.lname && !errors.name ? ref : null}
                            onChange={handleChange}
                          />

                          <label className="form-label">
                            Customer Last Name
                            <span className="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg">
                          {errors.lname && !errors.name ? errors.lname : null}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-local-phone icon-position"></i>

                          <input
                            type="text"
                            name="phone"
                            style={
                              errors.phone && !errors.lname && !errors.name
                                ? { border: "1px solid red" }
                                : null
                            }
                            className="form-control"
                            placeholder="Customer Phone#"
                            value={fields.phone || ""}
                            ref={
                              errors.phone && !errors.lname && !errors.name
                                ? ref
                                : null
                            }
                            onChange={handleChange}
                            onKeyUp={onlyNumbers}
                            onPaste={pasted}
                            maxLength={10}
                          />

                          <label className="form-label">
                            Customer Phone #
                            <span className="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg">
                          {errors.phone && !errors.lname && !errors.name
                            ? errors.phone
                            : null}
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-email-open icon-position"></i>

                          <input
                            type="text"
                            className="form-control"
                            name="emailid"
                            ref={
                              errors.emailid &&
                              !errors.phone &&
                              !errors.lname &&
                              !errors.name
                                ? ref
                                : null
                            }
                            style={
                              errors.emailid &&
                              !errors.phone &&
                              !errors.lname &&
                              !errors.name
                                ? { border: "1px solid red" }
                                : null
                            }
                            placeholder="Customer Email"
                            value={fields.emailid || ""}
                            onChange={handleChange}
                          />

                          <label className="form-label">
                            Customer Email
                            <span className="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg">
                          {errors.emailid &&
                          !errors.phone &&
                          !errors.lname &&
                          !errors.name
                            ? errors.emailid
                            : null}
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-account-circle icon-position"></i>

                          <input
                            type="text"
                            className="form-control"
                            style={
                              errors.sales_name_f &&
                              !errors.emailid &&
                              !errors.phone &&
                              !errors.lname &&
                              !errors.name
                                ? { border: "1px solid red" }
                                : null
                            }
                            name="sales_name_f"
                            placeholder="Employee First Name"
                            ref={
                              errors.sales_name_f &&
                              !errors.emailid &&
                              !errors.phone &&
                              !errors.lname &&
                              !errors.name
                                ? ref
                                : null
                            }
                            value={fields.sales_name_f || ""}
                            onChange={handleChange}
                          />

                          <label className="form-label">
                            Employee First Name
                            <span className="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg">
                          {errors.sales_name_f &&
                          !errors.emailid &&
                          !errors.phone &&
                          !errors.lname &&
                          !errors.name
                            ? errors.sales_name_f
                            : null}
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-account-circle icon-position"></i>

                          <input
                            type="text"
                            className="form-control"
                            name="sales_name_l"
                            placeholder="Employee Last Name"
                            style={
                              errors.sales_name_l &&
                              !errors.sales_name_f &&
                              !errors.emailid &&
                              !errors.phone &&
                              !errors.lname &&
                              !errors.name
                                ? { border: "1px solid red" }
                                : null
                            }
                            ref={
                              errors.sales_name_l &&
                              !errors.sales_name_f &&
                              !errors.emailid &&
                              !errors.phone &&
                              !errors.lname &&
                              !errors.name
                                ? ref
                                : null
                            }
                            value={fields.sales_name_l || ""}
                            onChange={handleChange}
                          />

                          <label className="form-label">
                            Employee Last Name
                            <span className="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg">
                          {errors.sales_name_l &&
                          !errors.sales_name_f &&
                          !errors.emailid &&
                          !errors.phone &&
                          !errors.lname &&
                          !errors.name
                            ? errors.sales_name_l
                            : null}
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-pin icon-position"></i>

                          {storeLoading ? (
                            <Skeleton count={1} height="48px" />
                          ) : (
                            <Select2Field
                              name="store_location"
                              label="Location"
                              options={locations}
                              value={fields["store_location"] || ""}
                            />
                          )}

                          <label className="form-label form-label-select">
                            Store Location
                            <span className="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg">
                          {!storeLoading && !locations.length
                            ? "The store location failed to load. Please refresh the page."
                            : errors.store_location &&
                              !errors.sales_name_l &&
                              !errors.sales_name_f &&
                              !errors.emailid &&
                              !errors.phone &&
                              !errors.lname &&
                              !errors.name
                            ? errors.store_location
                            : null}
                        </div>
                      </div>

                      <div className="col-md-8 col-sm-12 mb-3 mt-2">
                        <label class="form-label">
                          Which mattress or mattresses did the customer try?
                          <span className="gl-form-asterisk"></span>
                        </label>
                        <div className="row">
                          <div className="col-md-6 col-lg-3">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="try"
                                value="Beautyrest Black"
                                onChange={handleQuestion2Change}
                                id="flexCheckChecked"
                              />
                              <label
                                className="form-check-label fw-bold"
                                htmlFor="flexCheckChecked"
                              >
                                Beautyrest Black
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-3">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="try"
                                value="Serta iComfort"
                                onChange={handleQuestion2Change}
                                id="flexCheckChecked2"
                              />
                              <label
                                className="form-check-label fw-bold"
                                htmlFor="flexCheckChecked2"
                              >
                                Serta iComfort
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-3">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="try"
                                value="Tommy Bahama"
                                onChange={handleQuestion2Change}
                                id="flexCheckChecked3"
                              />
                              <label
                                className="form-check-label fw-bold"
                                htmlFor="flexCheckChecked3"
                              >
                                Tommy Bahama
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-3">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="try"
                                value="All of them"
                                onChange={handleQuestion2Change}
                                id="flexCheckChecked4"
                              />
                              <label
                                className="form-check-label fw-bold"
                                htmlFor="flexCheckChecked4"
                              >
                                All of them
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="errorMsg">
                          {errors.question2 &&
                          !errors.store_location &&
                          !errors.sales_name_l &&
                          !errors.sales_name_f &&
                          !errors.emailid &&
                          !errors.phone &&
                          !errors.lname &&
                          !errors.name
                            ? errors.question2
                            : null}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-walk icon-position"></i>

                          <Select2Field
                            name="question"
                            label="Option"
                            options={question}
                            value={fields["question"] || ""}
                          />

                          <label className="form-label form-label-select">
                            What mattress was your customer’s preferred brand?
                            <span className="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg d-inline">
                          {errors.question &&
                          !errors.question2 &&
                          !errors.store_location &&
                          !errors.sales_name_l &&
                          !errors.sales_name_f &&
                          !errors.emailid &&
                          !errors.phone &&
                          !errors.lname &&
                          !errors.name
                            ? errors.question
                            : null}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-shopping-basket icon-position"></i>

                          <Select2Field
                            name="question5"
                            label="Option"
                            options={question5}
                            value={fields["question5"] || ""}
                          />

                          <label className="form-label form-label-select">
                            Did the Customer purchase a mattress today?
                            <span className="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg d-inline">
                          {errors.question5 &&
                          !errors.question &&
                          !errors.question2 &&
                          !errors.store_location &&
                          !errors.sales_name_l &&
                          !errors.sales_name_f &&
                          !errors.emailid &&
                          !errors.phone &&
                          !errors.lname &&
                          !errors.name
                            ? errors.question5
                            : null}
                        </div>
                      </div>
                      <div className="col-md-12  col-sm-12 mb-3 mt-0 ">
                        <fieldset
                          className={`checkbox-group position-sleep ${
                            errors.checkederrorfive ? "danger-error" : ""
                          }`}
                        >
                          <legend className="checkbox-group-legend">
                            What is your current sleeping position?
                            <span className="text-danger">*</span>
                          </legend>

                          <div className="card-list">
                            <label className="checkbox-wrapper">
                              <input
                                type="radio"
                                name="position"
                                onChange={handleQuestion3Change}
                                className="checkbox-input"
                                value="On your back"
                              />
                              <span className="checkbox-tile">
                                <span className="checkbox-icon">
                                  <img
                                    src={onBack}
                                    alt="position icon"
                                    className="imgcolor img-fluid"
                                  />
                                </span>
                                <span className="checkbox-label">
                                  On your back
                                </span>
                              </span>
                            </label>
                          </div>
                          <div className="card-list">
                            <label className="checkbox-wrapper">
                              <input
                                type="radio"
                                name="position"
                                onChange={handleQuestion3Change}
                                className="checkbox-input"
                                value="On your side"
                              />
                              <span className="checkbox-tile">
                                <span className="checkbox-icon">
                                  <img
                                    src={onSide}
                                    alt="position icon"
                                    className="imgcolor img-fluid"
                                  />
                                </span>
                                <span className="checkbox-label">
                                  On your side
                                </span>
                              </span>
                            </label>
                          </div>
                          <div className="card-list">
                            <label className="checkbox-wrapper">
                              <input
                                type="radio"
                                name="position"
                                onChange={handleQuestion3Change}
                                className="checkbox-input"
                                value="On your stomach"
                              />
                              <span className="checkbox-tile">
                                <span className="checkbox-icon">
                                  <img
                                    src={onStomich}
                                    alt="position icon"
                                    className="imgcolor img-fluid"
                                  />
                                </span>
                                <span className="checkbox-label">
                                  On your stomach
                                </span>
                              </span>
                            </label>
                          </div>
                        </fieldset>

                        <div className="errorMsg">
                          {errors.question3 &&
                          !errors.question5 &&
                          !errors.question &&
                          !errors.question2 &&
                          !errors.store_location &&
                          !errors.sales_name_l &&
                          !errors.sales_name_f &&
                          !errors.emailid &&
                          !errors.phone &&
                          !errors.lname &&
                          !errors.name
                            ? errors.question3
                            : null}
                        </div>
                      </div>
                      <div className="col-md-12  col-sm-12 mb-3 mt-0">
                        <fieldset
                          className={`checkbox-group mattress_size ${
                            errors.checkederrorfive ? "danger-error" : ""
                          }`}
                        >
                          <legend className="checkbox-group-legend">
                            {/* What size Mattress are you looking for? */}
                            {/* What size mattress should showcase */}
                            What size mattress do you sleep on?
                            <span className="text-danger">*</span>
                          </legend>
                          <div className="card-list">
                            <label className="checkbox-wrapper">
                              <input
                                type="radio"
                                name="size"
                                onChange={handleQuestion4Change}
                                className="checkbox-input"
                                value="TXL"
                              />
                              <span className="checkbox-tile">
                                <span className="checkbox-icon">
                                  <img
                                    src={twinXl}
                                    alt="size icon"
                                    className="imgcolor img-fluid"
                                  />
                                </span>
                                <span className="checkbox-label">TXL</span>
                              </span>
                            </label>
                          </div>
                          <div className="card-list">
                            <label className="checkbox-wrapper">
                              <input
                                type="radio"
                                name="size"
                                onChange={handleQuestion4Change}
                                value="Twin"
                                className="checkbox-input"
                              />
                              <span className="checkbox-tile">
                                <span className="checkbox-icon">
                                  <img
                                    src={twin}
                                    alt="size icon"
                                    className="imgcolor img-fluid"
                                  />
                                </span>
                                <span className="checkbox-label">Twin</span>
                              </span>
                            </label>
                          </div>

                          <div className="card-list">
                            <label className="checkbox-wrapper">
                              <input
                                type="radio"
                                name="size"
                                onChange={handleQuestion4Change}
                                className="checkbox-input"
                                value="Full"
                              />
                              <span className="checkbox-tile">
                                <span className="checkbox-icon">
                                  <img
                                    src={full}
                                    alt="size icon"
                                    className="imgcolor img-fluid"
                                  />
                                </span>
                                <span className="checkbox-label">Full</span>
                              </span>
                            </label>
                          </div>
                          <div className="card-list">
                            <label className="checkbox-wrapper">
                              <input
                                type="radio"
                                name="size"
                                onChange={handleQuestion4Change}
                                className="checkbox-input"
                                value="Queen"
                              />
                              <span className="checkbox-tile">
                                <span className="checkbox-icon">
                                  <img
                                    src={queen}
                                    alt="size icon"
                                    className="imgcolor img-fluid"
                                  />
                                </span>
                                <span className="checkbox-label">Queen</span>
                              </span>
                            </label>
                          </div>
                          <div className="card-list">
                            <label className="checkbox-wrapper">
                              <input
                                type="radio"
                                name="size"
                                onChange={handleQuestion4Change}
                                className="checkbox-input"
                                value="King"
                              />
                              <span className="checkbox-tile">
                                <span className="checkbox-icon">
                                  <img
                                    src={king}
                                    alt="size icon"
                                    className="imgcolor img-fluid"
                                  />
                                </span>
                                <span className="checkbox-label">King</span>
                              </span>
                            </label>
                          </div>
                          <div className="card-list">
                            <label className="checkbox-wrapper">
                              <input
                                type="radio"
                                name="size"
                                onChange={handleQuestion4Change}
                                className="checkbox-input"
                                value="CAL King"
                              />
                              <span className="checkbox-tile">
                                <span className="checkbox-icon">
                                  <img
                                    src={calKing}
                                    alt="size icon"
                                    className="imgcolor img-fluid"
                                  />
                                </span>
                                <span className="checkbox-label">CAL King</span>
                              </span>
                            </label>
                          </div>
                        </fieldset>

                        <div className="errorMsg">
                          {errors.question4 &&
                          !errors.question5 &&
                          !errors.question3 &&
                          !errors.question &&
                          !errors.question2 &&
                          !errors.store_location &&
                          !errors.sales_name_l &&
                          !errors.sales_name_f &&
                          !errors.emailid &&
                          !errors.phone &&
                          !errors.lname &&
                          !errors.name
                            ? errors.question4
                            : null}
                        </div>
                      </div>

                      <div className="col-md-12 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-comments icon-position"></i>

                          <textarea
                            type="text"
                            className="form-control"
                            placeholder="Comments"
                            name="comments"
                            value={fields.comments || ""}
                            style={{ minHeight: "80px" }}
                            onChange={handleChange}
                          ></textarea>

                          <label className="form-label">
                            Additional Comments
                          </label>
                        </div>
                      </div>

                      <div className="clearfix"></div>
                      {/* <div className="col-md-8 col-sm-12 mb-3 mb-md-0 mt-0">
                        <div className="overflow-hider">
                          <ReCAPTCHA
                            sitekey={process.env.REACT_APP_API_GOOGLESITEKEY}
                            onChange={onCaptchaChange}
                            onErrored={NetworkError}
                            asyncScriptOnLoad={() => {
                              setCaptchaError("");
                            }}
                            ref={recaptchaRef}
                          />
                        </div>

                        <div className="errorMsg">
                          {captchaError !== "" ? (
                            <>
                              {captchaError}{" "}
                              {captchaError !== "Loading" && (
                                <>
                                  Or{" "}
                                  <button
                                    type="button"
                                    className="btn btn-danger btn-sm"
                                    onClick={reloadCaptcha}
                                  >
                                    Reset reCAPTCHA
                                  </button>
                                </>
                              )}
                            </>
                          ) : !errors.question4 &&
                            !errors.question5 &&
                            !errors.question3 &&
                            !errors.question &&
                            !errors.question2 &&
                            !errors.store_location &&
                            !errors.sales_name_l &&
                            !errors.sales_name_f &&
                            !errors.emailid &&
                            !errors.phone &&
                            !errors.lname &&
                            !errors.name ? (
                            errors.checkederrorcaptcha
                          ) : null}
                        </div>
                      </div> */}

                      <div className="col-md-4 col-sm-12 mt-0 ms-auto">
                        <div className="d-grid">
                          <button
                            className="btn btn-primary btn-lg"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="alert alert-info alert-info-custom">
                          Limit 1 per household, while supplies last. Some
                          restrictions apply, see store for detail.
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>

      {/* <Footer2 /> */}
    </>
  );
}
export default RegisterForm;
